import {useState, createContext, useContext} from 'react';
import {initializeExternalWallet} from 'api/routes/wallet';
import {getError} from 'utils/helpers';
import {useParams} from 'react-router-dom';

export const STEPS = {
    PHONE_VERIFICATION: 'Phone',
    WAITING_FOR_PAYMENT: 'WaitingForPayment',
};

export const PayWithMoMoContext = createContext({});
export const usePayWithMoMo = () => useContext(PayWithMoMoContext);

export const PayWithMoMoProvider = ({checkoutData = {}, children}) => {
    const [currentState, setCurrentState] = useState(STEPS.PHONE_VERIFICATION);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [phone_number, setPhoneNumber] = useState('');

    const {reference} = useParams();

    const {metadata, API, apiVersion} = checkoutData;
    const Authorization = metadata?.merchant?.publicKey;
    const config = {headers: {Authorization}};

    const updateCurrentState = newState => {
        setLoading(false);
        setErrorMsg('');
        setCurrentState(newState);
    };

    const handleChange = phone => {
        setPhoneNumber(phone);
        updateError('phone_number');
    };

    const updateError = name => {
        setFormErrors(prev => ({
            ...prev,
            [name]: '',
        }));
    };

    const handleError = message => {
        setLoading(false);
        setErrorMsg({msg: message});
    };

    const handlePhone = async () => {
        try {
            setLoading(true);
            setErrorMsg();

            const response = await API.put(
                `${apiVersion}${initializeExternalWallet(reference, 'momo')}`,
                {phone_number},
                config
            );

            const {status, data} = response || {};

            if ((status === 201 || status === 200) && (data.status || data.success)) {
                updateCurrentState(STEPS.WAITING_FOR_PAYMENT);
            } else handleError(data?.message);
        } catch (error) {
            handleError(getError(error.response.data));
        }
    };

    return (
        <PayWithMoMoContext.Provider
            value={{
                currentState,
                loading,
                errorMsg,
                formErrors,
                phone_number,
                handleChange,
                handlePhone,
            }}
        >
            {children}
        </PayWithMoMoContext.Provider>
    );
};
