import {useState} from 'react';
import {PaymentMethods} from 'components/constants';
import PayWithBankAccount from './PayWithBankAccount/PayWithBankAccount';
import {PayWithBankAccountProvider} from './PayWithBankAccount/PayWithBankAccountContext';
import PayWithBankTransfer from './PayWithBankTransfer/PayWithBankTransfer';
import PayWithCard from './PayWithCard/PayWithCard';
import {PayWithCardProvider} from './PayWithCard/PayWithCardContext';
import {PayWithMoMo} from './PayWithMoMo/PayWithMoMo';
import {PayWithMoMoProvider} from './PayWithMoMo/PayWithMomoContext';
import PayWithUSSD from './PayWithUSSD/PayWithUSSD';
import {PayWithOpayProvider} from './PayWIthOpay/PayWithOpayProvider';
import {PayWithOpay} from './PayWIthOpay/PayWithOpay';

const CheckoutForm = ({checkoutData}) => {
    const [selectedBank, setBank] = useState();

    const clientWidth = document.getElementById('checkout-form')?.clientWidth;

    const renderByMethod = () => {
        switch (checkoutData?.activeMethod?.name) {
            case PaymentMethods.CARD:
            default:
                return (
                    <PayWithCardProvider clientWidth={clientWidth} checkoutData={checkoutData}>
                        <PayWithCard />
                    </PayWithCardProvider>
                );

            case PaymentMethods.BANK_TRANSFER:
                return <PayWithBankTransfer checkoutData={checkoutData} />;

            case PaymentMethods.BANK_ACCOUNT:
                return (
                    <PayWithBankAccountProvider checkoutData={checkoutData}>
                        <PayWithBankAccount />
                    </PayWithBankAccountProvider>
                );

            case PaymentMethods.USSD:
                return (
                    <PayWithUSSD checkoutData={{...checkoutData, selectedBank}} setBank={setBank} />
                );
            case PaymentMethods.MOMO:
                return (
                    <PayWithMoMoProvider checkoutData={checkoutData}>
                        <PayWithMoMo />
                    </PayWithMoMoProvider>
                );
            case PaymentMethods.OPAY:
                return (
                    <PayWithOpayProvider checkoutData={checkoutData}>
                        <PayWithOpay />
                    </PayWithOpayProvider>
                );
        }
    };

    return (
        <div id="checkout-form" className="pt-4">
            {renderByMethod()}
        </div>
    );
};

export default CheckoutForm;
