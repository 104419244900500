import React from 'react';
import CustomPhoneInput from 'utils/CustomInput/CustomPhoneInput';
import {getPhone} from 'utils/helpers';
import CustomSpinner from 'utils/Spinner/Spinner';

export const PhoneNumberForm = ({context = {}}) => {
    const {phone_number, loading, formErrors, handleChange, handlePhone} = context;

    const handleSubmit = e => {
        e.preventDefault();
        handlePhone();
    };

    return (
        <form className="row" onSubmit={handleSubmit}>
            <div className="col-12 text-center my-4">
                <span className="g-bold text--gray">Enter phone number to continue</span>
            </div>

            <div className="col-12">
                <CustomPhoneInput
                    cls="mb-4"
                    error={formErrors?.phone_number}
                    label="Phone Number"
                    name="phone_number"
                    placeholder="Enter Phone Number"
                    onChange={e => handleChange(getPhone(e))}
                    value={phone_number}
                />
            </div>

            <div className="col-12 pt-4">
                <button
                    className="btn btn--lg btn--primary g-bold"
                    type="submit"
                    disabled={loading || phone_number?.length < 9}
                >
                    {loading ? <CustomSpinner /> : 'Continue'}
                </button>
            </div>
        </form>
    );
};
