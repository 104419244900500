export const {
    initializeCheckout,
    getCheckoutData,
    getTransactionStatus,
    initiateCard,
    chargeCard,
    getTokenizeCard,
    chargeTokenizedCard,
    initiateBankAccountCharge,
    chargeAccount,
    resolveBank,
    getPaymentLink,
    initializePaymentLink,
    getPaymentPage,
    initializePaymentPage,
    verifyPhoneNumber,
    initializeExternalWallet,
    getSubscription,
    activateSubscription,
    sendOTP,
    getVirtualAccount,
    getQrCode,
    initializeQrCode,
} = {
    initializeCheckout: '/checkout/initialize',
    getCheckoutData: (reference, apiVersion) => `${apiVersion}/checkout/${reference}`,
    getTransactionStatus: (reference, apiVersion) => `${apiVersion}/checkout/status/${reference}`,
    initiateCard: `/checkout/charge/card`,
    chargeTokenizedCard: token => `/checkout/charge/card/${token}`,
    getTokenizeCard: `/tokenized-card/public`,
    verifyPhoneNumber: `/checkout/charge/bank-account/bank-list`,
    initiateBankAccountCharge: `/checkout/charge/bank-account/initiate-charge`,
    chargeAccount: '/checkout/charge/bank-account/charge-account',
    resolveBank: `/checkout/resolve-bank`,
    getPaymentPage: reference => `/payment-page/${reference}`,
    initializePaymentPage: reference => `/payment-page/initialize/${reference}`,
    getPaymentLink: reference => `/paymentlink/${reference}`,
    initializePaymentLink: reference => `/paymentlink/initialize/${reference}`,
    initializeExternalWallet: (reference, scheme) =>
        `/checkout/${reference}/charge/external-wallets/${scheme}`,
    getSubscription: reference => `/subscription/public/${reference}`,
    activateSubscription: reference => `/subscription/activate/${reference}`,
    sendOTP: '/misc/public/send-otp',
    getVirtualAccount: (reference, apiVersion) =>
        `${apiVersion}/checkout/${reference}/virtual-account`,
    getQrCode: (reference, apiVersion) => `${apiVersion}/qr-code/${reference}/public`,
    initializeQrCode: (reference, apiVersion) => `${apiVersion}/qr-code/initialize/${reference}`,
};
