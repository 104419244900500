import React, {useState} from 'react';
import CustomInputBox from 'utils/CustomInput/CustomInputBox';
import {ReactComponent as Copy} from 'assets/images/copy.svg';
import {ReactComponent as Call} from 'assets/images/call.svg';
import {ReactComponent as Info} from 'assets/images/info.svg';
import CustomSelect from 'utils/Selects/CustomSelect/CustomSelect';
import {allUSDBanks} from 'components/Checkouts/Checkout/extra';
import {CONFIRM} from 'components/constants';
import {copyToClipboard} from 'utils/helpers';
import Alert from 'utils/Alert/Alert';

const PayWithUSSDForm = ({setBank, setCurrentStep, checkoutData}) => {
    const [msg, setMsg] = useState();

    const {amount, metadata, selectedBank, timeLeft, virtualAccount} = checkoutData || {};
    const {hideUssdDialButton} = metadata || {};

    const dialCode = `*${selectedBank?.ussdCode}*${amount}*${virtualAccount?.account_number}#`;

    const formCls = selectedBank ? 'col-sm-6' : 'col-12';

    const handleCopy = () => {
        copyToClipboard(dialCode);
        setMsg({
            variant: 'success',
            msg: 'USSD Code Copied Successfully!!!',
        });
    };

    return (
        <>
            {msg && <Alert {...msg} onClose={() => setMsg()} />}

            <div className="text-center pt-3">
                <span className="g-bold text--gray">
                    Select your bank and dial the code on your device
                </span>
            </div>

            <div className="row pt-4 g-bold text--gray">
                <div className={`${formCls}`}>
                    <CustomSelect
                        name="selectedBank"
                        label="Select Bank"
                        placeholder="Select Bank"
                        value={selectedBank}
                        onChange={e => setBank(e)}
                        options={allUSDBanks}
                        getOptionLabel={({label}) => label}
                        getOptionValue={ussdCode => ussdCode}
                        required
                    />
                </div>

                {selectedBank && (
                    <>
                        <div className="col-sm-6">
                            <CustomInputBox
                                name="account_name"
                                label="Account Name"
                                value={virtualAccount?.account_name}
                                placeholder="Account Name"
                                pattern="[\d| ]"
                                readonly
                            />
                        </div>

                        <div className="col-12 flex__between bg-light rounded w-100 px-3 py-4">
                            <h2 className="g-bold m-0 text--primary">{dialCode}</h2>
                            <div className="d-flex align-items-center">
                                <Copy
                                    width={25}
                                    height={25}
                                    className="pointer"
                                    onClick={handleCopy}
                                />

                                {!hideUssdDialButton && (
                                    <a
                                        className="bg--primary px-3 py-2 ms-3 rounded"
                                        target="_blank"
                                        href={`tel:${dialCode}`}
                                    >
                                        <Call width={20} height={20} className="pointer" />
                                    </a>
                                )}
                            </div>
                        </div>

                        <div className="mt-3">
                            <Info />

                            <span className="px-2">
                                Use this account for this transaction only.{' '}
                            </span>
                            {timeLeft && (
                                <>
                                    Expires in
                                    <span className="text-warning ms-2">
                                        {timeLeft?.minutes}:{timeLeft?.seconds}
                                    </span>
                                </>
                            )}
                        </div>

                        <div className="mt-5">
                            <button
                                className="btn btn--lg btn--primary g-bold"
                                onClick={() => setCurrentStep(CONFIRM)}
                            >
                                I have completed payment
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default PayWithUSSDForm;
