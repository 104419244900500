import InitializeError from 'components/Checkouts/Checkout/common/InitializeError';
import '../Checkouts/Checkout/Checkout.scss';

export const ErrorPage = () => {
    return (
        <div className="checkout">
            <div className="checkout__container">
                <div className="checkout__main">
                    <InitializeError title={'An Error Has Occurred'} errorMsg="Invalid Reference" />
                </div>
            </div>
        </div>
    );
};
