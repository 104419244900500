import {useContext, useEffect, useState} from 'react';
import Layout from 'components/Common/Layout';
import {useParams} from 'react-router-dom';
import {getPaymentPage} from 'api/routes/wallet';
import BigSpinner from 'utils/Spinner/BigSpinner';
import {EXPIRED, IN_ACTIVE, responseStatus} from 'components/constants';
import {getError, getVersion} from 'utils/helpers';
import PaymentPageForm from './PaymentPageForm/PaymentPageContainer';
import FailedState from 'utils/ResponseState/FailedState';
import AppContext from 'components/App/AppContext';
import {AppMeta} from 'components/App/AppMeta';

const {LOADING, FAILED, IDLE, SUCCESS} = responseStatus;

const PaymentPage = ({location}) => {
    const {colors} = useContext(AppContext);
    const [status, setStatus] = useState(IDLE);
    const [errorMsg, setErrorMsg] = useState();
    const [payment, setPayment] = useState({});

    const showTransparent = status === LOADING || errorMsg;
    const pryColor = payment?.primaryColor || colors?.primaryColor;
    const secColor = payment?.secondaryColor || colors?.secondaryColor;

    const {reference} = useParams();
    const {API, apiVersion, isPro} = getVersion(location);

    useEffect(() => {
        getPaymentLinkData();
        // eslint-disable-next-line
    }, []);

    const getPaymentLinkData = async () => {
        try {
            setStatus(LOADING);

            const response = await API.get(`${apiVersion}${getPaymentPage(reference)}`);

            const {status, data} = response || {};

            if (status === 200 && (data.status || data.success)) {
                const linkStatus = data?.data?.status;
                if (linkStatus === EXPIRED.toLowerCase()) setError('Link has expired');
                else if (linkStatus === IN_ACTIVE) setError('Link is not active');
                else {
                    setStatus(SUCCESS);
                    setPayment(data.data);
                }
            } else setError(data?.message);
        } catch (error) {
            setError(getError(error.response?.data || error.response));
        }
    };

    const setError = message => {
        setStatus(FAILED);
        setErrorMsg(message);
    };

    const renderByStatus = () => {
        switch (status) {
            case LOADING:
                return (
                    <div className="mt-5 pt-5 flex__center  invoice__content">
                        <BigSpinner />;
                    </div>
                );

            case FAILED:
                return (
                    <div className="mt-5 pt-5 flex__center invoice__content">
                        <FailedState errorMsg={errorMsg} refetch={getPaymentLinkData} />
                    </div>
                );

            case SUCCESS:
                return (
                    <PaymentPageForm
                        paymentData={{
                            ...payment,
                            reference,
                            API,
                            isPro,
                            apiVersion,
                            refetch: getPaymentLinkData,
                        }}
                    />
                );
            default:
                return '';
        }
    };

    return (
        <>
            <AppMeta title="Payment Page" />
            <Layout
                colors={{
                    primaryColor: showTransparent ? 'transparent' : pryColor,
                    secColor: showTransparent ? 'transparent' : secColor,
                }}
            >
                <div className="">{renderByStatus()}</div>
            </Layout>
        </>
    );
};

export default PaymentPage;
