export const responseStatus = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    EMPTY: 'EMPTY',
    FAILED: 'FAILED',
    SUCCESS: 'SUCCESS',
    SUCCESSFUL: 'success',
    PENDING_AUTH: 'PENDING_AUTH',
    PENDING_AUTH_CAPTURE: 'PENDING_AUTH_CAPTURE',
    INVALID: 'INVALID',
    FAILURE: 'FAILURE',
};

export const PAYMENT = '/payments';
export const PAYMENT_LINK = 'payment_link';
export const QR_CODE = 'qr_code';

export const CLOSE = 'close';
export const DONE = 'done';
export const WAITING_FOR_PAYMENT = 'waiting-for-payment';
export const CANCEL_WAITING_FOR_PAYMENT = 'cancel-waiting-for-payment';
export const IN_ACTIVE = 'inactive';

export const PaymentMethods = {
    CARD: 'Card',
    BANK_TRANSFER: 'Bank Transfer',
    BANK_ACCOUNT: 'Bank Account',
    USSD: 'USSD',
    MOMO: 'MoMo',
    PALM_PAY: 'Palmpay',
    OPAY: 'Opay',
};

export const CARDTYPES = {
    visa: 'visa',
    MASTERCARD: 'mastercard',
    AMERICAN_EXPRESS: 'american-express',
    DINNER_CLUB: 'diners-club',
    DISCOVER: 'discover',
    JCB: 'jcb',
    UNION_PAY: 'unionpay',
    MAESTRO: 'maestro',
    MIR: 'mir',
    ELO: 'elo',
    HIPER: 'hiper',
    HIPERCARD: 'hipercard',
};

export const currencies = {
    NGN: 'NGN',
    USD: 'USD',
    EUR: 'EUR',
    GBP: 'GBP',
};

export const INITIAL = 'INITIAL';
export const OTP = 'OTP';
export const CONFIRM = 'CONFIRM';
export const EXPIRED = 'EXPIRED';
export const ISSUED = 'issued';
export const PARTIALLY_PAID = 'partially_paid';
export const PAID = 'paid';
export const ERROR = 'ERROR';
export const SAVED = 'SAVED';
export const SAVE = 'SAVE';
export const DRAFT = 'DRAFT';
export const ACTIVE = 'ACTIVE';
export const READY = 'READY';
export const _INACTIVE = '_INACTIVE';
export const PENDING = 'PENDING';
export const PAYING = 'PAYING';
export const PAID_BACK = 'PAID_BACK';
export const WARNING = 'WARNING';
export const SUCCESS = 'SUCCESS';
export const SUCCESSFUL = 'SUCCESSFUL';
export const SETTLED = 'SETTLED';
export const ACCEPTED = 'ACCEPTED';
export const REJECTED = 'REJECTED';
export const AWAITING = 'AWAITING';
export const RESOLVED = 'RESOLVED';
export const ESCALATED = 'ESCALATED';
export const DANGER = 'DANGER';
export const FAILED = 'FAILED';
export const CLOSED = 'CLOSED';
export const BLOCKED = 'BLOCKED';
export const INVITED = 'INVITED';
export const PROCESSING = 'PROCESSING';
export const POSTED = 'POSTED';
export const ACTIVATED = 'ACTIVATED';
export const NOT_ACTIVE = 'NOT_ACTIVE';
export const REMITTED = 'REMITTED';
export const REFUNDED = 'REFUNDED';
export const CREATED = 'CREATED';
export const CHALLENGED = 'CHALLENGED';
export const STATIC = 'static';
