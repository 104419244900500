import {useContext} from 'react';
import AppContext from 'components/App/AppContext';
import BlusaltLogo from 'assets/images/logo.svg';
import {getCurrencySymbol, toCurrency} from 'utils/helpers';

const CheckoutHeader = ({link, checkoutData}) => {
    const {metadata: appMetadata} = useContext(AppContext);
    const {amount, currency, metaD, metadata, logo: merchantLogo} = checkoutData || {};
    const {name: username} = checkoutData || {};
    const {name, email, logo} = metadata?.merchant || {};
    const currentEmail = metadata?.customer?.email || email;
    const currencyCode = getCurrencySymbol(currency);
    const isAggregator = checkoutData?.metadata?.isAggregator;

    const selectedLogo = isAggregator
        ? appMetadata?.company_icon
        : metadata?.logoLink ||
          metaD?.logoLink ||
          logo ||
          merchantLogo ||
          appMetadata?.company_icon ||
          BlusaltLogo;

    return (
        <div className="flex__between checkout__header">
            <img src={selectedLogo} alt={name} className="logo img-fluid" />

            <div>
                {link ? (
                    <h4 className="m-0 fw-bold text-white">{username}</h4>
                ) : (
                    <div className="text-white">
                        <h5 className="m-0 fw-bold">
                            PAY
                            <span className="ps-2">
                                {currencyCode} {toCurrency(amount)}
                            </span>
                        </h5>
                        <span>{currentEmail}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CheckoutHeader;
