import {useState} from 'react';
import CheckoutForm from 'components/Checkouts/Checkout/CheckoutContent/CheckoutForm/CheckoutForm';
import CheckoutHeader from 'components/Checkouts/Checkout/CheckoutContent/CheckoutHeader/CheckoutHeader';
import CheckoutMethodSelect from 'components/Checkouts/Checkout/CheckoutContent/CheckoutMethodSelect/CheckoutMethodSelect';
import CheckoutWrapper from 'components/Checkouts/Checkout/common/CheckoutWrapper';
import {ReactComponent as USSD} from 'assets/images/ussd.svg';
import {ReactComponent as Bank} from 'assets/images/bank.svg';

const methods = [
    {
        name: 'Bank Transfer',
        Icon: Bank,
        slug: 'bank-transfer',
        envs: ['dev', 'test', 'live'],
    },
    {
        name: 'USSD',
        Icon: USSD,
        slug: 'bank-transfer',
        envs: ['dev', 'test', 'live'],
    },
];

export const CollectionContent = ({checkoutData, handleClose}) => {
    const [activeMethod, setActiveMethod] = useState(methods?.[0]);

    return (
        <CheckoutWrapper isFrame={true} sendEvent={handleClose}>
            <CheckoutHeader checkoutData={{...checkoutData, activeMethod}} />

            <div className="p-5">
                <CheckoutMethodSelect
                    activeMethod={activeMethod}
                    methods={methods}
                    setActiveMethod={setActiveMethod}
                />
                <CheckoutForm
                    checkoutData={{...checkoutData, activeMethod, setActiveMethod, methods}}
                />
            </div>
        </CheckoutWrapper>
    );
};
