import {useEffect} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {QueryClientProvider} from 'react-query';
import Invoice from '../Invoices/Invoice/Invoice';
import Checkout from 'components/Checkouts/Checkout/Checkout';
import CheckoutInitialize from 'components/Checkouts/CheckoutInitialize/CheckoutInitialize';
import PaymentLink from 'components/PaymentLink/PaymentLink';
import PaymentPage from 'components/PaymentPage/PaymentPage';
import {QrCode} from 'components/QrCode/QrCode';
import AppWrapper from './AppWrapper';
import Subscription from 'components/Subscription/Subscription';
import {useReactQueryClient} from 'hooks';
import {CollectionPayment} from 'components/Collection/CollectionPayment';
import {validateReference} from 'utils/helpers';
import {ErrorPage} from './404';

const App = () => {
    const queryClient = useReactQueryClient();

    useEffect(() => {
        if (typeof window !== undefined) {
            const {pathname, origin} = window.location;
            const isValid = validateReference(pathname);
            if (!isValid) {
                window.location.href = `${origin}/error`;
            }
        }
    }, []);

    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <AppWrapper>
                    <Switch>
                        <Route exact path="/collection/fund-wallet" component={CollectionPayment} />
                        <Route exact path="/payments/page/:reference" component={PaymentPage} />
                        <Route exact path="/payments/link/:reference" component={PaymentLink} />
                        <Route exact path="/payments/initialize" component={CheckoutInitialize} />
                        <Route exact path="/payments/:reference" component={Checkout} />
                        <Route
                            exact
                            path="/payments/subscription/:reference"
                            component={Subscription}
                        />
                        <Route exact path="/payments/qr-code/:reference" component={QrCode} />
                        <Route exact path="/page/:reference" component={PaymentPage} />
                        <Route exact path="/invoice/:reference" component={Invoice} />
                        <Route exact path="/link/:reference" component={PaymentLink} />
                        <Route exact path="/subscription/:reference" component={Subscription} />
                        <Route exact path="/initialize" component={CheckoutInitialize} />
                        <Route exact path="/error" component={ErrorPage} />
                        <Route exact path="/:reference" component={Checkout} />
                    </Switch>
                </AppWrapper>
            </QueryClientProvider>
        </BrowserRouter>
    );
};

export default App;
