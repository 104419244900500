import React, {useState} from 'react';
import {ReactComponent as Copy} from 'assets/images/copy.svg';
import {ReactComponent as Info} from 'assets/images/info.svg';
import {copyToClipboard, toCurrency} from 'utils/helpers';
import {CONFIRM, responseStatus} from 'components/constants';
import Alert from 'utils/Alert/Alert';

const {SUCCESSFUL} = responseStatus;

const PayWithBankTransferForm = ({checkoutData, setCurrentStep}) => {
    const [msg, setMsg] = useState();

    const {amount, currency, metadata, timeLeft, virtualAccount} = checkoutData || {};
    const {account_number, account_name} = virtualAccount ?? {};

    const handleCopy = () => {
        copyToClipboard(account_number);
        setMsg({
            variant: SUCCESSFUL,
            msg: 'Account Number Copied Successfully!!!',
        });
    };

    return (
        <div className="g-bold text--gray">
            {msg && <Alert {...msg} onClose={() => setMsg()} />}

            <div className="text-center pt-3">
                <span>
                    Send
                    <span className="text--primary px-2">
                        {currency} {toCurrency(amount)}
                    </span>
                    to the account details below <br />
                    using your internet or banking app.
                </span>
            </div>

            <div className="mt-5">
                <div className="bg-light rounded w-100 px-3 py-4">
                    <h4>
                        {metadata?.bank_name} - {account_name}
                    </h4>

                    <div className="flex__between">
                        <h1 className="text--primary m-0">{account_number}</h1>
                        <div
                            className="bg--primary pointer px-3 py-2 ms-3 rounded"
                            onClick={handleCopy}
                        >
                            <Copy width={20} height={20} className="me-2" />
                            Copy
                        </div>
                    </div>
                </div>

                <div className="mt-3">
                    <Info />
                    <span className="px-2">Use this account for this transaction only. </span>
                    {timeLeft && (
                        <>
                            Expires in
                            <span className="text-warning ms-2">
                                {timeLeft?.minutes}:{timeLeft?.seconds}
                            </span>
                        </>
                    )}
                </div>

                <div className="mt-5">
                    <button
                        className="btn btn--lg btn--primary g-bold"
                        onClick={() => setCurrentStep(CONFIRM)}
                    >
                        I have completed payment
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PayWithBankTransferForm;
