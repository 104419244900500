import React from 'react';
import {ReactComponent as QRCODE} from 'assets/images/qr_code.svg';
import {STEPS, usePayWithOpay} from './PayWithOpayProvider';

export const ScanQRCode = () => {
    const {updateCurrentState} = usePayWithOpay();

    return (
        <div className="text-center">
            <div className="col-12 text-center my-4">
                <span className="g-bold text--gray">Scan QR Code to Pay</span>
            </div>
            <QRCODE className="me-2" />

            <p className="my-4 fw-bold">Click continue after you’ve made payment </p>

            <div className="flex__between mt-5">
                <button
                    className="btn btn--lg btn--primary--bordered g-bold"
                    type="button"
                    onClick={() => updateCurrentState(STEPS.PHONE_VERIFICATION)}
                >
                    Back
                </button>
                <button
                    className="btn btn--lg btn--primary g-bold"
                    type="button"
                    onClick={() => updateCurrentState(STEPS.WAITING_FOR_PAYMENT)}
                >
                    Continue
                </button>
            </div>
        </div>
    );
};
