import {useEffect, useState} from 'react';
import queryString from 'query-string';
import BigSpinner from 'utils/Spinner/BigSpinner';
import TransactionStatus from 'components/Checkouts/Checkout/common/TransactionStatus';
import {CollectionContent} from './CollectionContent';
import {CheckoutLayout} from 'components/Checkouts/CheckoutSC';
import InitializeError from 'components/Checkouts/Checkout/common/InitializeError';
import {
    CANCEL_WAITING_FOR_PAYMENT,
    CLOSE,
    DONE,
    INITIAL,
    responseStatus,
    SUCCESS,
    WAITING_FOR_PAYMENT,
} from 'components/constants';

const {LOADING, FAILED, IDLE} = responseStatus;

export const CollectionPayment = ({location}) => {
    const [status, setStatus] = useState(IDLE);
    const [errorMsg, setErrorMsg] = useState();
    const [checkoutData, setCheckoutData] = useState({});

    const {search} = location || {};
    const checkoutParams = queryString.parse(search);

    useEffect(() => {
        getParams();
        // eslint-disable-next-line
    }, []);

    const sendEvent = data => window.parent.postMessage(data, '*');

    const handleClose = ({eventData = {}}) => {
        sendEvent({name: CLOSE, ...eventData});
    };

    const getParams = () => {
        if (!checkoutParams?.account_name) setError('Account name is missing.');
        else if (!checkoutParams?.account_number) setError('Account number is missing.');
        else if (!checkoutParams?.bank_name) setError('Bank name is missing.');
        else if (!checkoutParams?.amount) setError('Amount is missing.');
        else if (!checkoutParams?.currency) setError('Currency is missing.');
        else {
            setStatus(INITIAL);
            setCheckoutData({
                currency: checkoutParams?.currency,
                amount: checkoutParams?.amount,
                virtualAccount: {
                    account_name: checkoutParams?.account_name,
                    account_number: checkoutParams?.account_number,
                },
                metadata: {
                    bank_name: checkoutParams?.bank_name,
                    account_name: checkoutParams?.account_name,
                    customer: {email: checkoutParams?.email},
                    logoLink: checkoutParams?.logoLink,
                },
                callBackOnWFP: () => sendEvent({name: WAITING_FOR_PAYMENT}),
                callBackOnCancelWFP: () => sendEvent({name: CANCEL_WAITING_FOR_PAYMENT}),
            });
        }
    };

    const setError = message => {
        setStatus(FAILED);
        setErrorMsg(message);
    };

    const renderByStatus = () => {
        switch (status) {
            case LOADING:
                return (
                    <div className="init__loader">
                        <BigSpinner />
                    </div>
                );
            case FAILED:
                return (
                    <InitializeError
                        isFrame
                        errorMsg={errorMsg}
                        retry={getParams}
                        sendEvent={handleClose}
                    />
                );
            case SUCCESS:
                return (
                    <TransactionStatus
                        showBtn
                        title="Transaction Successful"
                        message="Your transaction is now successful"
                        handleClick={() => sendEvent({name: DONE})}
                        btnName="Done"
                    />
                );
            case INITIAL:
                return (
                    <CollectionContent
                        checkoutData={{...checkoutData, setTranzStatus: setStatus}}
                        handleClose={handleClose}
                    />
                );
            default:
                return '';
        }
    };
    return (
        <CheckoutLayout colors={{primaryColor: checkoutParams?.primaryColor}} className="checkout">
            <div className="checkout__container">
                <div className="checkout__main">{renderByStatus()}</div>
            </div>
        </CheckoutLayout>
    );
};
