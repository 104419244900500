import Alert from 'utils/Alert/Alert';
import {PhoneNumberForm, WaitingForPayment} from 'components/Checkouts/Checkout/common';
import {STEPS, usePayWithOpay} from './PayWithOpayProvider';
import {ScanQRCode} from './ScanQRCode';

export const PayWithOpay = () => {
    const context = usePayWithOpay();
    const {currentState, errorMsg, setErrorMsg} = context;

    const render = () => {
        switch (currentState) {
            case STEPS.PHONE_VERIFICATION:
            default:
                return <PhoneNumberForm context={context} />;

            case STEPS.QR_SCANNING:
                return <ScanQRCode />;

            case STEPS.WAITING_FOR_PAYMENT:
                return <WaitingForPayment />;
        }
    };
    return (
        <>
            {errorMsg && <Alert {...errorMsg} onClose={() => setErrorMsg()} />}
            {render()}
        </>
    );
};
